import React from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import Forms from './Forms';
import HeaderPrivacy from '../Privacy/HeaderPrivacy';
import HeroPrivacy from '../Privacy/HeroPrivacy';

function Contact() {
    const { t } = useTranslation();
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderPrivacy drawer={drawer} action={drawerAction.toggle} />
            <HeroPrivacy
                title={t('menu.links.contact')}
                breadcrumb={[
                    { link: '/', title: t('menu.links.home') },
                    { link: '/contact', title: t('menu.links.contact') },
                ]}
            />
            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Contact;
