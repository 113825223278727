/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Forms() {
    const { t } = useTranslation();
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>{t('contact_section.get_in_touch')}</h3>
                                <p>{t('contact_section.form_description')}</p>
                                <div className="single-info">
                                    <h5>{t('contact_section.headquarters.title')}</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        {t('contact_section.headquarters.address')}
                                        <br /> {t('contact_section.headquarters.city')}
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>{t('contact_section.phone.title')}</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        {t('contact_section.phone.number')}
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>{t('contact_section.support.title')}</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        {t('contact_section.support.email')}
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>{t('contact_section.follow_us.title')}</h5>
                                    <a className="fac" href="#">
                                        <i className={t('contact_section.follow_us.facebook')}></i>
                                    </a>
                                    <a className="twi" href="#">
                                        <i className={t('contact_section.follow_us.twitter')}></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className={t('contact_section.follow_us.linkedin')}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>{t('contact_section.let_connect')}</h4>
                                <p>{t('contact_section.let_connect_description')}</p>
                                <form action="#" method="post" className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="f-name"
                                            placeholder={t(
                                                'contact_section.form_fields.first_name'
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="l-name"
                                            placeholder={t('contact_section.form_fields.last_name')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={t('contact_section.form_fields.email')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder={t('contact_section.form_fields.phone')}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="suject"
                                            placeholder={t('contact_section.form_fields.subject')}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder={t('contact_section.form_fields.message')}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                {t('contact_section.form_fields.terms_conditions')}{' '}
                                                <Link to="/privacy">{t('menu.links.privacy')}</Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input
                                            type="submit"
                                            name="submit"
                                            value={t('contact_section.submit_button')}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28767.008636412156!2d-100.33251922096677!3d25.675402282378034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629587079b4b83%3A0xfae261356b66709d!2sCentro%2C%20Monterrey%2C%20N.L.!5e0!3m2!1ses!2smx!4v1699076260814!5m2!1ses!2smx"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </>
    );
}

export default Forms;
