import React from 'react';
import { useTranslation } from 'react-i18next';
import heroThumbOne from '../../assets/images/hero-image.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

function HeroHomeOne() {
    const { t } = useTranslation();
    return (
        <>
            <section className="power_leads_hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="power_leads_hero-content">
                                <span>{t('hero_section.subtitle')}</span>
                                <h1 className="power_leads_title">{t('hero_section.title')}</h1>
                                <p>{t('hero_section.body')}</p>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-apple" /> {t('buttons.ios')}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="#">
                                            <i className="fab fa-google-play" />{' '}
                                            {t('buttons.android')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="power_leads_hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} width="60%" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
