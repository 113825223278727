import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';
import chatP from '../../assets/images/chat-portrait.png';
import geoLocation from '../../assets/images/geolocation.png';
import routeTracking from '../../assets/images/route-tracking.png';

function FeaturesHomeOne({ className }) {
    const [tab, setTab] = useState('setting');
    const { t } = useTranslation();
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section className={`power_leads_features-area pt-100 ${className}`} id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="power_leads_features-tabs-btn">
                            <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    onClick={(e) => handleClick(e, 'setting')}
                                    className={`nav-link ${tab === 'setting' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    <i className="fas fa-comment" />
                                    {t('description_section.chat.menu')}
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'report')}
                                    className={`nav-link ${tab === 'report' ? 'active' : ''}`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    href="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-street-view" />{' '}
                                    {t('description_section.geolocation.menu')}
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'notice')}
                                    className={`nav-link ${tab === 'notice' ? 'active' : ''}`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    href="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-route" />{' '}
                                    {t('description_section.route_tracking.menu')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === 'setting' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-thumb text-center wow animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img
                                                src={chatP}
                                                style={{
                                                    maxHeight: 618,
                                                    objectFit: 'cover',
                                                    maxWidth: 502,
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>{t('description_section.chat.subtitle')}</span>
                                            <h3 className="title" data-i18n="[html]content.body">
                                                {t('description_section.chat.title')}
                                            </h3>
                                            <p>{t('description_section.chat.body')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'report' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img
                                                src={geoLocation}
                                                style={{
                                                    maxHeight: 618,
                                                    objectFit: 'cover',
                                                    maxWidth: 502,
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>
                                                {t('description_section.geolocation.subtitle')}
                                            </span>
                                            <h3 className="title" data-i18n="[html]content.body">
                                                {t('description_section.geolocation.title')}
                                            </h3>
                                            <p>{t('description_section.geolocation.body')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'notice' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img
                                                src={routeTracking}
                                                style={{
                                                    maxHeight: 618,
                                                    objectFit: 'cover',
                                                    maxWidth: 502,
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="power_leads_features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>
                                                {t('description_section.route_tracking.subtitle')}
                                            </span>
                                            <h3 className="title" data-i18n="[html]content.body">
                                                {t('description_section.route_tracking.title')}
                                            </h3>
                                            <p>{t('description_section.route_tracking.body')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shapeSix} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shapeSeven} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shapeEight} alt="" />
            </div>
        </section>
    );
}

export default FeaturesHomeOne;
