import React from 'react';
import { useTranslation } from 'react-i18next';

function HeroAbout() {
    const { t } = useTranslation();
    return (
        <>
            <div className="power_leads_about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="power_leads_about-top-title">
                                <h2 className="title">{t('about_us_section.top_title.title')}</h2>
                                <p>{t('about_us_section.top_title.subtitle')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="power_leads_about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="power_leads_about-page-content">
                                <h3 className="title">
                                    {t('about_us_section.page_content.title')}
                                </h3>
                                <p>{t('about_us_section.page_content.content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
