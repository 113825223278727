import React from 'react';
import { useTranslation } from 'react-i18next';
import thumb from '../../assets/images/fun-fact-thumb.png';

function HeroService() {
    const { t } = useTranslation();
    return (
        <>
            <div className="power_leads_page-title-area power_leads_page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="power_leads_page-title-item">
                                <span>{t('services.subtitle')}</span>
                                <h3 className="title">{t('services.title')}</h3>
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroService;
