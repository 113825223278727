import React from 'react';
import { useTranslation } from 'react-i18next';
import thumb from '../../assets/images/thumb-ppp.png';

function TrafficHomeOne() {
    const { t } = useTranslation();
    return (
        <section className="power_leads_traffic-area pt-140 pb-180">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="power_leads_traffic-title">
                            <span>{t('platform.subtitle')}</span>
                            <h3 className="title">{t('platform.title')}</h3>
                            <p>{t('platform.body')}</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="power_leads_traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">{t('platform.points.0.title')}</h5>
                                    <p>{t('platform.points.0.body')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="power_leads_traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">{t('platform.points.1.title')}</h5>
                                    <p>{t('platform.points.1.body')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="power_leads_traffic-service item-3">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">{t('platform.points.2.title')}</h5>
                                    <p>{t('platform.points.2.body')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="power_leads_traffic-service item-4">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">{t('platform.points.3.title')}</h5>
                                    <p>{t('platform.points.3.body')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <a className="main-btn" href="#">
                                        Learn More
                                        <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeOne;
