import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ToggleLang() {
    const { i18n } = useTranslation();
    const [currentLang, setcurrentLang] = useState(window.localStorage.getItem('lang') ?? 'en');
    const handleClick = () => {
        const newLang = currentLang === 'es' ? 'en' : 'es';
        i18n.changeLanguage(newLang);
        setcurrentLang(newLang);
        window.localStorage.setItem('lang', newLang);
    };
    return (
        <button
            className="btn btn-light ml-10"
            style={{ padding: 10 }}
            type="button"
            onClick={handleClick}
        >
            {currentLang === 'es' ? (
                <>
                    <span className="fi fi-es"></span> Es
                </>
            ) : (
                <>
                    <span className="fi fi-us"></span> En
                </>
            )}
        </button>
    );
}
