import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import keymex from '../../assets/images/keymex.png';

function HeroService({ campaingName }) {
    const { t } = useTranslation();
    const [logo, setlogo] = useState();
    useEffect(() => {
        // eslint-disable-next-line default-case
        switch (campaingName) {
            case 'keymex':
                setlogo(keymex);
                break;
        }
    }, [campaingName]);
    return (
        <>
            <div className="power_leads_page-title-area power_leads_page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="power_leads_page-title-item">
                                <h3 className="title">{t(`campaings.${campaingName}.title`)}</h3>
                                <div
                                    className="thumb"
                                    style={{
                                        position: 'absolute',
                                        top: -30,
                                        right: 0,
                                        width: '500px',
                                    }}
                                >
                                    <img src={logo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroService;
