import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';

function FooterHomeOne({ className }) {
    const { t } = useTranslation();
    return (
        <>
            (
            <section className={`power_leads_footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">
                                    {t('power_leads_footer.about_widget.title')}
                                </h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">
                                            {t('power_leads_footer.about_widget.about_us')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            {t('power_leads_footer.about_widget.contact')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">{t('power_leads_footer.support.title')}</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">
                                            {t('power_leads_footer.support.community')}
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="/privacy">
                                            {t('power_leads_footer.support.privacy_policy')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">
                                    {t('power_leads_footer.get_in_touch.title')}
                                </h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" />{' '}
                                            {t('power_leads_footer.get_in_touch.email')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" />{' '}
                                            {t('power_leads_footer.get_in_touch.phone')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" />{' '}
                                            {t('power_leads_footer.get_in_touch.address')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> {t('buttons.ios')}
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" />{' '}
                                                {t('buttons.android')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>
                                        {t('power_leads_footer.copyright', {
                                            year: new Date().getFullYear(),
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
