import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import HeaderService from './HeaderCampaign';
import HeroService from './HeroCampaign';
import DetailCampaign from './DetailCampaign';

function Campaign() {
    const params = useParams();
    const { name } = params;
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            <HeroService campaingName={name} />
            <DetailCampaign campaingName={name} />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Campaign;
