import React from 'react';
import { useTranslation } from 'react-i18next';
import Sponser1 from '../../assets/images/keymex.png';
import Sponser2 from '../../assets/images/subzero.png';
import Sponser3 from '../../assets/images/locksmith.png';
import Sponser4 from '../../assets/images/lone-star.jpg';
import Sponser5 from '../../assets/images/sponser-10.jpg';
import sponserShape from '../../assets/images/sponser-shape.png';

function Sponser({ className }) {
    const { t } = useTranslation();
    return (
        <>
            <section className={`power_leads_sponser-area pb-100 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="power_leads_section-title text-center">
                                <h3 className="power_leads_title">
                                    {t('sponsor.title')} <br />
                                </h3>
                                <p>{t('sponsor.body')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="power_leads_sponser-box d-flex justify-content-center">
                                <div
                                    className="sponser-item"
                                    style={{ backgroundColor: '#1B2831' }}
                                >
                                    <img src={Sponser1} alt="" />
                                </div>
                                <div
                                    className="sponser-item"
                                    style={{ backgroundColor: '#1B2831' }}
                                >
                                    <img src={Sponser2} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser3} alt="" style={{ objectFit: 'cover' }} />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser4} alt="" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sponser-shape">
                    <img src={sponserShape} alt="" />
                </div>
            </section>
        </>
    );
}

export default Sponser;
