import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import thumb from '../../assets/images/services.png';

function DetailsService() {
    const { t } = useTranslation();
    return (
        <>
            <section className="power_leads_service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>123 Monterrey NL
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>aava-digital.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/privacy">
                                        <i className="fal fa-eye"></i>
                                        <span>{t('menu.links.privacy')}</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-mobile"></i>
                                        <span>Screenshots</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">{t('services.w')}</h3>
                                    <p>{t('services.body')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
