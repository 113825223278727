import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Navigation() {
    const { t } = useTranslation();
    return (
        <>
            <ul>
                <li>
                    <Link to="/">{t('menu.links.home')}</Link>
                </li>
                <li>
                    <Link to="/service">{t('menu.links.service')}</Link>
                </li>
                <li>
                    <a href="#">
                        {t('menu.links.campaings')} <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/campaings/keymex">Keymex</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        {t('menu.links.know_us')} <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/privacy">{t('menu.links.privacy')}</Link>
                        </li>
                        <li>
                            <Link to="/about-us">{t('menu.links.aboutus')}</Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
