import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import signupThumb from '../../assets/images/signup-thumb.png';

function SignupForm({ className }) {
    const { t } = useTranslation();
    return (
        <>
            <div className={`power_leads_signup-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="power_leads_signup-box">
                                <h3 className="title">{t('signup_section.title')}</h3>
                                <form action="#">
                                    <div className="input-box">
                                        <input
                                            type="text"
                                            placeholder={t('signup_section.form_placeholder_name')}
                                        />
                                    </div>
                                    <div className="input-box">
                                        <input
                                            type="email"
                                            placeholder={t('signup_section.form_placeholder_email')}
                                        />
                                    </div>
                                    <div className="input-box">
                                        <button type="submit">
                                            {t('signup_section.signup_button')}
                                        </button>
                                    </div>
                                    <div className="power_leads_checkbox_common checkbox_style2">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="checkbox2"
                                                id="checkbox4"
                                            />
                                            <label htmlFor="checkbox4">
                                                <span></span>
                                                {t('signup_section.checkbox_label')}{' '}
                                                <Link to="/privacy">
                                                    {t('signup_section.terms_conditions_link')}
                                                </Link>
                                                .
                                            </label>
                                        </div>
                                    </div>
                                </form>
                                <div className="thumb">
                                    <img src={signupThumb} alt="" />{' '}
                                    {/* Asegúrate de tener signupThumb importado correctamente */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignupForm;
