import React from 'react';
import { useTranslation } from 'react-i18next';

function ServicesAbout() {
    const { t } = useTranslation();
    return (
        <>
            <section className="power_leads_services-2-area pt-90 pb-55" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="power_leads_section-title">
                                <h3 className="power_leads_title">
                                    {t('services_section_2.title')}
                                </h3>
                                <p>{t('services_section_2.subtitle')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {t('services_section_2.services', { returnObjects: true }).map(
                            (service, index) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="col-lg-4 col-md-6 wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay={`${200 * index}ms`}
                                >
                                    <div className="power_leads_single-service-2 power_leads_single-service-about mt-30">
                                        <div className="icon">
                                            <i className={service.icon}></i>
                                        </div>
                                        <h4 className="title">{service.title}</h4>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesAbout;
