import React from 'react';
import { useTranslation } from 'react-i18next';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function DownloadApp({ className }) {
    const { t } = useTranslation();
    return (
        <>
            <section className={`power_leads_download-area pt-150 pb-160 mb-90 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="power_leads_download-content">
                                <span>{t('download_section.subtitle')}</span>
                                <h3 className="title">{t('download_section.title')}</h3>
                                <p>{t('download_section.body')}</p>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-apple" />
                                            <span>
                                                {t('download_section.btn')} <span>iOS</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="#">
                                            <i className="fab fa-google-play" />
                                            <span>
                                                {t('download_section.btn')} <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="download-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="download-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default DownloadApp;
