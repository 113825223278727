import React from 'react';
import { useTranslation } from 'react-i18next';

function ServicesHomeOne({ className }) {
    const { t } = useTranslation();
    return (
        <section className={`power_leads_service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="power_leads_section-title text-center">
                            <h3 className="power_leads_title">{t('ServiceTitle')}</h3>
                            <p>{t('ServiceBody')}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="power_leads_single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                            style={{ minHeight: 400, height: 400 }}
                        >
                            <div className="icon">
                                <i className="mdi mdi-calendar text-white fa-2x"></i>
                                <span>1</span>
                            </div>
                            <h4 className="power_leads_title">{t('S1.title')}</h4>
                            <p>{t('S1.body')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="power_leads_single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                            style={{ minHeight: 400, height: 400 }}
                        >
                            <div className="icon">
                                <i className="mdi mdi-arrange-send-backward text-white fa-2x"></i>
                                <span>2</span>
                            </div>
                            <h4 className="power_leads_title">{t('S2.title')}</h4>
                            <p>{t('S2.body')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="power_leads_single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                            style={{ minHeight: 400, height: 400 }}
                        >
                            <div className="icon">
                                <i className="mdi mdi-cellphone-marker text-white fa-2x"></i>
                                <span>3</span>
                            </div>
                            <h4 className="power_leads_title">{t('S3.title')}</h4>
                            <p>{t('S3.body')}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="power_leads_single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                            style={{ minHeight: 400, height: 400 }}
                        >
                            <div className="icon">
                                <i className="mdi mdi-map-marker text-white fa-2x"></i>
                                <span>4</span>
                            </div>
                            <h4 className="power_leads_title">{t('S4.title')}</h4>
                            <p>{t('S4.body')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
