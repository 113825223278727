import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

function DetailCampaign({ campaingName = '' }) {
    const { t, i18n } = useTranslation();
    const tel = t(`campaings.${campaingName}.phone`);
    const [isActive, setisActive] = useState(false);
    return (
        <>
            {i18n.exists(`campaings.${campaingName}`) ? (
                <section className="power_leads_service-details-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-content">
                                    <div className="content">
                                        <h3 className="title">
                                            {t(`campaings.${campaingName}.subject`)}
                                        </h3>
                                        <p
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: t(`campaings.${campaingName}.body`),
                                            }}
                                        ></p>
                                        <div className="condition-check">
                                            <input
                                                checked={isActive}
                                                id="terms-conditions"
                                                type="checkbox"
                                                onChange={() => {
                                                    setisActive(!isActive);
                                                }}
                                            />
                                            <label htmlFor="terms-conditions">
                                                {t(`campaings.${campaingName}.confirmationText`)}
                                            </label>
                                        </div>
                                        {isActive && (
                                            <a
                                                href={`sms:${tel}&body=${campaingName.toUpperCase()}`}
                                                className="btn btn-primary mt-2"
                                            >
                                                {t(`campaings.${campaingName}.button`)}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <Redirect to="/not-exist-campaing" />
            )}
        </>
    );
}

export default DetailCampaign;
