/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';

import thumb from '../../assets/images/privacy-banner.png';
import HeaderPrivacy from './HeaderPrivacy';
import HeroPrivacy from './HeroPrivacy';

function Privacy() {
    const [drawer, drawerAction] = useToggle(false);
    const { t } = useTranslation();
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderPrivacy action={drawerAction.toggle} />
            <HeroPrivacy
                title={t('menu.links.privacy')}
                breadcrumb={[
                    { link: '/', title: t('menu.links.home') },
                    { link: '/privacy', title: t('menu.links.privacy') },
                ]}
            />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                <h3 className="title">{t('privacy_policy.subtitle')}</h3>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t('privacy_policy.body.intro'),
                                    }}
                                ></p>
                                <ol>
                                    {t('privacy_policy.body.purposes', { returnObjects: true }).map(
                                        (purpose, index) => (
                                            <li
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                className="li-terms"
                                                dangerouslySetInnerHTML={{ __html: purpose }}
                                            />
                                        )
                                    )}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Privacy;
